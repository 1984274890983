import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "components/CustomButtons/Button.js";
import { format } from 'date-fns'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  InstapaperShareButton,
  EmailShareButton,


  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  RedditIcon,
  EmailIcon,
  InstapaperIcon,
  FacebookIcon
} from 'react-share';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: '960px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  icons: {
    width: "17px",
    height: "17px",
    color: "#FFFFFF"
  }
}));

export default function BlogEntry(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Card className={classes.card} id={"/entry" + props.index}>
      <CardHeader
        title={props.title}
        subheader={format(new Date(props.date), 'dd.MM.yyyy')}
      />
      <CardMedia
        className={classes.media}
        image={props.image}
        title={props.text}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.text}
        </Typography>
      </CardContent>
      <CardActions disableSpacing >

        <Button aria-describedby={id} round justIcon
          color="primary" onClick={handleClick}>
          <ShareIcon className={classes.icons}/>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ display: 'flex' }}>
            <FacebookShareButton
              url={"duaarasho.at/blog/#entry" + props.index}
              quote={props.title}
              className="Demo__some-network__share-button">
              <IconButton aria-label="share">
                <FacebookIcon size={32}
                  round />
              </IconButton>
            </FacebookShareButton>
            <TwitterShareButton
              url={"duaarasho.at/blog/#entry" + props.index}
              title={props.title}
              className="Demo__some-network__share-button">
              <IconButton aria-label="share">

                <TwitterIcon
                  size={32}
                  round />
              </IconButton>
            </TwitterShareButton>
            <WhatsappShareButton
              url={"duaarasho.at/blog/#entry" + props.index}
              title={props.title}
              separator=":: "
              className="Demo__some-network__share-button">
              <IconButton aria-label="share">

                <WhatsappIcon size={32} round />
              </IconButton>
            </WhatsappShareButton>
            <LinkedinShareButton
              url={"duaarasho.at/blog/#entry" + props.index}
              windowWidth={750}
              windowHeight={600}
              title={props.title}
              summary={props.text}
              className="Demo__some-network__share-button">
              <IconButton aria-label="share">

                <LinkedinIcon
                  size={32}
                  round />
              </IconButton>
            </LinkedinShareButton>
            <RedditShareButton
              url={"duaarasho.at/blog/#entry" + props.index}
              title={props.title}
              windowWidth={660}
              windowHeight={460}
              className="Demo__some-network__share-button">
              <IconButton aria-label="share">

                <RedditIcon
                  size={32}
                  round />
              </IconButton>
            </RedditShareButton>
            <InstapaperShareButton
              url={"duaarasho.at/blog/#entry" + props.index}
              title={props.title}
              className="Demo__some-network__share-button">
              <IconButton aria-label="share">

                <InstapaperIcon
                  size={32}
                  round />
              </IconButton>
            </InstapaperShareButton>
            <EmailShareButton
              url={"duaarasho.at/blog/#entry" + props.index}
              subject={props.title}
              body="body"
              className="Demo__some-network__share-button">
              <IconButton aria-label="share">

                <EmailIcon
                  size={32}
                  round />
              </IconButton>
            </EmailShareButton>
          </div>
        </Popover>
        <Button
          target="_blank"
          href={props.url}
          round
          color="primary"
          style={{ width: "150px", marginLeft: "auto" }}>
          <i className={` ${"fab fa-amazon"} ${classes.icons}`} /> Buy Now
        </Button>
        {props.details.length > 1 &&
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      </CardActions>
      {props.details.length > 1 &&
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              {props.details}
            </Typography>
          </CardContent>
        </Collapse>
      }

    </Card>
  );
}