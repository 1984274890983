import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import BlogEntry from "components/Blog_Entry.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/duaa_blog.jpg";

import styles from "assets/jss/material-kit-react/views/blog.js";
import { graphql } from "gatsby"
import { Divider } from "@material-ui/core";
import 'assets/css/fa.css';
import "assets/scss/material-kit-react.scss";
import SEO from "components/seo.js"
const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const data = props.data.allWordpressWpBlogEntry.nodes;
  const { ...rest } = props;
  console.log(props);
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRounded
  );
  return (
    <div>
      <Header
        color="transparent"
        brand="Duaa Rasho"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
        {...rest}
      />
      <SEO image={profile} pathname={"/blog/"}></SEO>
      <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container} style={{paddingBottom: "100px"}}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Duaa Rasho</h3>
                    <h6>My Blog about life, releases and my work</h6>
                    <Button justIcon link className={classes.margin5} href="https://at.linkedin.com/in/دعاء-رشو-duaa-rasho-353201111">
                      <i className={"fab fa-linkedin"} />
                    </Button>
                    <Button href="https://www.instagram.com/dedaa_ra/?hl=en" justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button href="https://m.facebook.com/profile.php?id=100010897200948" justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            {data && data.map((element,index) => {
              return<div key={index}> 
              <BlogEntry
              url={element.acf.link_to_book} index={index} date={element.date} text={element.acf.text} title={element.title} details={element.acf.details} image={element.acf.image.source_url}></BlogEntry>
              <Divider style={{margin: "20px 0px"}}></Divider>
              </div>
            })}

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export const postQuery = graphql`
query MyQuery {
  allWordpressWpBlogEntry {
    nodes {
      acf {
        details
        text
        image {
          source_url
        }
        link_to_book
      }
      title
      date
    }
  }
}

`