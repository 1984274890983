/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "gatsby";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import EventNoteIcon from '@material-ui/icons/EventNote';
// @material-ui/icons
import { Apps } from "@material-ui/icons";
import SvgIcon from '@material-ui/core/SvgIcon';
import PersonIcon from '@material-ui/icons/Person';
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  let defaultHeight
  let defaultWidth

  if (typeof window !== `undefined`) {
    defaultHeight = window.innerHeight
    defaultWidth = window.innerWidth
  }

  const useWindowSize = () => {
    const [dimensions, setDimensions] = useState({
      windowHeight: defaultHeight,
      windowWidth: defaultWidth,
    })

    useEffect(() => {
      const handler = () => setDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      })

      window.addEventListener(`resize`, handler)
      return () => window.removeEventListener(`resize`, handler)
    }, [])

    return dimensions
  }
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Books"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={"books"}
          dropdownList={[
            <Link to="/myescape/" className={classes.dropdownLink}>
              My Escape
            </Link>,
            <Link to="/messagewillnotarrive/" className={classes.dropdownLink}>
              Message will not arrive
            </Link>
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Link to="/blog/">
          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <SvgIcon className={classes.icons} {...props}>
              <path d="M3 3V21H21V3H3M18 18H6V17H18V18M18 16H6V15H18V16M18 12H6V6H18V12Z" />
            </SvgIcon> Blog
        </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/aboutme/">

          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <PersonIcon className={classes.icons} /> About me
        </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/socialresponsibility/">

          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <SportsKabaddiIcon className={classes.icons} /> Social Responsibility
        </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Events"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={"event"}
          dropdownList={[
            <Link to="/galleries/">
              <Button
                color="transparent"
                target="_blank"
                className={classes.navLink}
              >
                <PhotoLibraryIcon className={classes.icons} /> Galleries
          </Button>
            </Link>,
            <Link to="/appointments/">
              <Button
                color="transparent"
                target="_blank"
                className={classes.navLink}
              >
                <EventNoteIcon className={classes.icons} /> Appointments
      </Button>
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/contact/">
          <Button
            color="transparent"
            target="_blank"
            className={classes.navLink}
          >
            <ContactMailIcon className={classes.icons} /> Contact
        </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
        <Tooltip
          id="instagram-twitter"
          title="Follow me on linkedIn"
          placement={useWindowSize.windowWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://at.linkedin.com/in/دعاء-رشو-duaa-rasho-353201111"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-linkedin"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow me on facebook"
          placement={useWindowSize.windowWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://m.facebook.com/profile.php?id=100010897200948"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow me on instagram"
          placement={useWindowSize.windowWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/dedaa_ra/?hl=en"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
